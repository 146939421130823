import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import styled from 'styled-components';

import Layout from '../components/Layout/Layout';
import SEO from '../components/seo';

/**
 * Página de biografía.
 */
const BioPage = () => {

  // Imagen de la biografía
  const data = useStaticQuery(bioImageQuery);

  // Renderización
  return (
    <Layout>
      <SEO title="Biografía" />
      <ImageWrapper>
        <Image fluid={data.imageSharp.fluid} alt="Me" />
      </ImageWrapper>
      <BioWrapper>
        <p>
          Canaria sin mar es mi mejor definición,
          me define la distancia de lo que amo y añoro,
          de lo que elegí hace tiempo y sin arrepentirme
          nunca no puedo evitar echarlo de menos cada día,
          porque necesito el mar tanto como escribir.
        </p>
        <p>
          Este un deseo dormido que tenía que ver la luz algún día
          como estos poemas que llevan guardados mucho tiempo.
        </p>
      </BioWrapper>
    </Layout>
  )
};

// Consulta para obtener la imagen de la biografía
export const bioImageQuery = graphql`
  query {
    imageSharp(fluid: {originalName: {eq: "about.jpg"}}) {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

// Componente de estilo para el texto de la biografía
const BioWrapper = styled.div`
    min-width: 200px;
    max-width: 480px;
    margin: 2rem auto;

    p {
      font-family: var(--ff-poem);
      font-size: 20px;
      font-weight: 400;
      font-style: italic;
      line-height: 1.3;
      text-align: center;
    }

    @media (max-width: 440px) {
    p {
      font-size: 16px;
    }
  }
`;

// Componente de estilo para la imagen
const ImageWrapper = styled.div`
  max-width: 440px;
  min-width: 300px;
  width: 60%;
  margin: auto;
  border: 10px solid var(--clr-white);
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  @media (max-width: 440px) {
    width: 300px;
  }
`;

export default BioPage;
